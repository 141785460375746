<template>
    <div class="bid_main_wrap">
        <div class="bid_content_wrap">
            <div class="bid_title_wrap">
                <h1 class="title">{{ $t('bid') }}</h1>
            </div>

            <div class="content_wrap">
                <div class="img_wrap">
                    <div class="b_img">
                        <img :src="returnThumbnail(mainImg)"/>
                    </div>
                    <swiper class="small_img_wrap" ref="swiper" :options="swiperOption" v-if="thumnailStatus"
                            :slidesPerView="'auto'" @click="onClick"
                            @slideChange="slideChange">
                        <swiper-slide class="s_img">
                            <img :src="returnSmallThumbnail(orgItemImg)"/>
                        </swiper-slide>
                        <swiper-slide class="s_img"
                                      v-for="(data,index) in thumnailImgs"
                                      v-if="data.org_name != 'no_image.png'"
                                      :key="`detail${index}`">
                            <img :src="returnSmallThumbnail(data.org_url)"/>
                        </swiper-slide>
                    </swiper>
                </div>
                <div class="inform_wrap">
                    <div class="inform_header">
                        <div class="inform_h_header">
                            <ul class="thumb_img">
                                <li>
                                    <el-popover
                                            :open-delay="500"
                                            :offset="0"
                                            @show="getMemberData(itemData,'im_originator')"
                                            :append-to-body="false"
                                            :popper-options="{
                                              boundariesElement: 'body',
                                              gpuAcceleration: true,
                                              positionFixed: true,
                                              preventOverflow: true,}"
                                            trigger="click"
                                            popper-class="bid_popover">
                                        <img slot="reference" class="card_profile"
                                             :src="returnItemProfile(itemData,'originator')">
                                        <div class="popover_wrap" v-if="checkMemberData()"
                                             @click="moveMember(`creator/${itemData.im_originator}`)">
                                            <div class="popover_top">
                                                <div class="popover_creator">
                                                    {{ $t('k_creator') }}
                                                </div>
                                                <div class="popover_nickname">
                                                    <img class="nickname_img" :src="returnMemberImg()">
                                                    <div class="popover_text_list">
                                                        <div class="popover_nickname_list">
                                                            <div class="nick_text">{{ memberData.mb_nick }}</div>
                                                            <div class="pop_curator" v-if="memberData.g_idx=== 2">
                                                                <span>Curator</span></div>
                                                            <div class="pop_curator second_color"
                                                                 v-if="memberData.mb_premium===1">
                                                                <span>Special</span></div>
                                                        </div>
                                                        <div class="popover_nickname2">
                                                            <img class="pn_img"
                                                                 src="@/assets/image/main/heart1.png">
                                                            <div>{{ memberData.likeCnt }}</div>
                                                            <div>ITEM</div>
                                                            <div>{{ memberData.itemCnt }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="popover_bottom">
                                                <div>
                                                    {{ memberData.mb_introduce }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="popover_wrap" v-else>
                                            <div class="popover_top no_profile">
                                                <div class="popover_creator no_profile_creator">
                                                    {{ $t('k_creator') }}
                                                </div>
                                                <div class="popover_nickname no_profile_nickname">
                                                    <img class="nickname_img no_profile_img"
                                                         src="@/assets/image/main/no_profile.png">
                                                    <div class="popover_text_list no_profile_text">
                                                        <div class="popover_nickname_list">
                                                            <div class="nick_text no_profile_text">{{
                                                                    $t('notInfo')
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="popover_bottom no_profile_bottom">
                                                <div class="pop_pover_div no_profile_div">
                                                    {{ $t('notProvider') }}
                                                </div>
                                            </div>
                                        </div>
                                    </el-popover>
                                </li>

                                <li>
                                    <el-popover
                                            :open-delay="500"
                                            :offset="0"
                                            @show="getMemberData(itemData,'provider')"
                                            :append-to-body="false"
                                            :popper-options="{
                                                boundariesElement: 'body',
                                                gpuAcceleration: true,
                                                positionFixed: true,
                                                preventOverflow: true,
                                            }"
                                            trigger="click"
                                            popper-class="bid_popover">
                                        <img slot="reference" class="card_profile"
                                             :src="returnItemProfile(itemData,'provider')">
                                        <div class="popover_wrap" v-if="checkMemberData()"
                                             @click="moveMember(`creator/${itemData.provider}`)">
                                            <div class="popover_top">
                                                <div class="popover_creator">
                                                    {{ $t('owner') }}
                                                </div>
                                                <div class="popover_nickname">
                                                    <img class="nickname_img" :src="returnMemberImg()">
                                                    <div class="popover_text_list">
                                                        <div class="popover_nickname_list">
                                                            <div class="nick_text">{{ memberData.mb_nick }}</div>
                                                            <div class="pop_curator" v-if="memberData.g_idx=== 2">
                                                                <span>Curator</span></div>
                                                            <div class="pop_curator second_color"
                                                                 v-if="memberData.mb_premium===1">
                                                                <span>Special</span></div>
                                                        </div>
                                                        <div class="popover_nickname2">
                                                            <img class="pn_img"
                                                                 src="@/assets/image/main/heart1.png">
                                                            <div>{{ memberData.likeCnt }}</div>
                                                            <div>ITEM</div>
                                                            <div>{{ memberData.itemCnt }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="popover_bottom">
                                                <div>
                                                    {{ memberData.mb_introduce }}
                                                </div>
                                            </div>
                                        </div>
                                    </el-popover>
                                </li>
                                <li>
                                    <el-popover
                                            :open-delay="500"
                                            :offset="0"
                                            @show="getMemberData(itemData,'ibc_curator')"
                                            :append-to-body="false"
                                            :popper-options="{
                                                boundariesElement: 'body',
                                                gpuAcceleration: true,
                                                positionFixed: true,
                                                preventOverflow: true,
                                            }"
                                            trigger="click"
                                            popper-class="bid_popover no_profile_popover">
                                        <img slot="reference" class="card_profile" v-if="checkCurator(itemData)"
                                             :src="returnItemProfile(itemData,'curator')">
                                        <div class="popover_wrap"
                                             @click="moveMember(`creator/${itemData.ibc_curator}`)">
                                            <div class="popover_top">
                                                <div class="popover_creator">
                                                    {{ $t('curator') }}
                                                </div>
                                                <div class="popover_nickname">
                                                    <img class="nickname_img" :src="returnMemberImg()">
                                                    <div class="popover_text_list">
                                                        <div class="popover_nickname_list">
                                                            <div class="nick_text">{{ memberData.mb_nick }}</div>
                                                            <div class="pop_curator" v-if="memberData.g_idx=== 2">
                                                                <span>Curator</span></div>
                                                            <div class="pop_curator second_color"
                                                                 v-if="memberData.mb_premium===1">
                                                                <span>Special</span></div>
                                                        </div>
                                                        <div class="popover_nickname2">
                                                            <img class="pn_img"
                                                                 src="@/assets/image/main/heart1.png">
                                                            <div>{{ memberData.likeCnt }}</div>
                                                            <div>ITEM</div>
                                                            <div>{{ memberData.itemCnt }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="popover_bottom">
                                                <div>
                                                    {{ memberData.mb_introduce }}
                                                </div>
                                            </div>
                                        </div>
                                    </el-popover>
                                </li>
                            </ul>
                            <div class="time_remain" v-if="itemData.i_status === 'auction'">
                                <span>{{ returnItemType(itemData) }}</span>
                            </div>
                        </div>
                        <div class="header_title">
                            <h3 class="title_left">{{ returnCategoryName(itemData) }}</h3>
                            <h3 class="title_right">{{ itemData.i_name }}</h3>
                        </div>
                    </div>
                    <div class="inform_content">
                        <div class="content_top">
                            <ul>
                                <li v-if="itemData.ia_is_minimum === 1">
                                    <h3 class="sub_title sub_left">{{ $t('mini_bid') }}</h3>
                                    <div class="sub_right">
                                        <span class="sub_span_mg">{{ returnCommas(itemData.ia_minimum) }} Mg</span><br/>
                                        <small class="sub_span_w">({{ returnMingle(itemData.ia_minimum) }})</small>
                                    </div>
                                </li>
                                <li>
                                    <h3 class="sub_title sub_left">{{ $t('max_bid') }}</h3>
                                    <div class="sub_right">
                                        <span class="sub_span_mg">{{ returnCommas(itemData.i_price) }} Mg</span><br/>
                                        <small class="sub_span_w">({{ returnMingle(itemData.i_price) }})</small>
                                    </div>
                                </li>
                                <li v-if="itemData.ia_is_immediately === 1">
                                    <h3 class="sub_title sub_left">{{ $t('i_buy') }}</h3>
                                    <div class="sub_right ">
                                        <span class="sub_span_mg">{{
                                                returnCommas(itemData.ia_immediately)
                                            }} Mg</span><br/>
                                        <small class="sub_span_w">({{ returnMingle(itemData.ia_immediately) }})</small>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="content_bottom">
                            <div class="bid_amount">
                                <h3 class="content_name">{{ $t('a_bid') }}</h3>
                                <input type="tel"
                                       placeholder=""
                                       v-model="bid_price"
                                       @input="checkMaxNumber('bid_price',bid_price)"
                                />
                            </div>
                            <div class="caption_check">
                                <div class="mt10 ml20"
                                     v-if="validation.hasError('bid_price')"><p>{{
                                        validation.firstError('bid_price')
                                    }}</p></div>
                            </div>

                            <ul class=" content_bottom_1">
                                <li class="commission_btn li_top">
                                    <h3 class="content_name sub_left" v-if="itemData.ibp_is_change  === 0"
                                        @click="openModal('terms-modal', 'fees')">{{
                                            $t('f_tax')
                                        }}</h3>
                                    <h3 class="content_name sub_left" v-else @click="openModal('terms-modal', 'fees')">
                                        {{
                                            $t('tax')
                                        }}</h3>
                                    <div class="sub_right">
                                        <span><i class=" max_width sub_span_mg">{{
                                                setFeesData(bid_price)
                                            }}Mg</i></span><br/>
                                        <span class="sub_span_w">({{ returnMingle(fees) }})</span>
                                    </div>
                                </li>
                                <li class="li_bottom">
                                    <h3 class="content_name sub_left">{{ $t('f_bid') }}</h3>
                                    <div class="sub_right">
                                        <span class="color "><i class="max_width sub_span_mg">{{
                                                returnCommas(returnTotalPrice())
                                            }}Mg</i></span><br/>
                                        <span class="sub_span_w">({{ returnMingle(returnTotalPrice()) }})</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="search_item">
                            <el-checkbox v-model="termsCheck" @click="changeTerms">
                                <span class="item_text_span bold"
                                      @click="openModal('terms-modal', 'auctionBuy')">{{ $t('terms_bid') }}</span>
                                <span v-html="$t('agree_terms2')"></span>

                                <!--                                <span class="item_text_span">{{ $t('agree_terms2') }}</span>-->
                            </el-checkbox>
                        </div>

                        <div class="btn_wrap" v-if="checkMemberType()">
                            <button class="cancel" @click="backEvent">{{ $t('cancel') }}</button>
                            <button class="gobid" @click="buyConfirmFunc">{{ $t('bid') }}</button>
                        </div>
                        <div class="btn_wrap" v-else>
                            <button class="cancel cancel_100" @click="backEvent">{{ $t('back_msg') }}</button>
                        </div>

                        <div class="caption_check">
                            <div class="mt10 val_cap" v-if="termsMsgType"><p>{{ $t('caption_check') }}</p></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import {mapState} from "vuex";
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins"
import priceMixins from "@/mixins/priceMixins";
import itemValidator from "@/mixins/validators/itemValidator";
import AuthMixins from "@/mixins/AuthMixins";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import alertMixins from "@/mixins/alertMixins";

let timeInterval = null;
export default {
    name: "ItemDetailBidLayout",
    mixins: [imageOption, dateMixins, priceMixins, itemValidator, AuthMixins, imageResizeMixins, alertMixins],
    components: {
        Swiper,
        SwiperSlide,
    },
    inject: ['itemDetailSetData'],
    provide() {
        return {}
    },
    props: {
        itemData: {},
    },
    data() {
        return {

            visible: false,
            itemCategorys: {},
            swiperOption: {
                slidesPerView: 4,
                spaceBetween: 8,
                grabCursor: true,
                autoWidth: true,
            },
            memberData: {},
            fees: '',
            termsCheck: false,
            termsMsgType: false,
            errorMsgType: false,
            errorMsg: '',
            bid_priceCheck: false,
            currentTime: '',
            inputMsgType: false,

            getDataStatus: false,
        }
    },
    beforeRouterEnter() {

    },
    created() {
        this.initItemData();
    },
    mounted() {
        timeInterval = setInterval(this.getCurrentTime, 1000);
        EventBus.$on('ItemDetailBidLayoutValidator', this.checkValidator);
    },
    beforeDestroy() {
        clearInterval(timeInterval)
        EventBus.$off('ItemDetailBidLayoutValidator');
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            itemCategoryList: state => state.itemCategory.itemCategoryList,
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        initItemData() {
            if (!util.isEmpty(this.itemData) && !util.isEmpty(this.itemData.ItemFile) && !util.isEmpty(this.itemData.ItemFile.Detail)) {
                this.thumnailImgs = this.itemData.ItemFile.Detail;
                this.orgItemImg = this.itemData.ItemFile.Item[0].org_url;
                this.mainImg = this.orgItemImg;
                this.thumnailStatus = true;
            } else {
                this.thumnailImgs = [];
                this.mainImg = '';
                this.thumnailStatus = false;
            }
        },
        initSetData() {
            this.parentChange('immediately', this.itemData.ia_minimum);
            this.parentChange('i_price', this.itemData.i_price);
        },
        parentChange(type, value) {
            this[type] = value;
            this.itemDetailSetData(type, value);
        },
        openModal(data, type) {
            this.$modal.show(`${data}`, {termsType: type});
        },

        returnItemProfile(data, type) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile[type])) {
                return `${data.profile[type][0].org_url}?${this.setImageOptions(45, 45, 'png')}`;
            }
        },
        checkCurator(data) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile.curator)) {
                return true
            }
            return false;
        },
        getCurrentTime() {
            this.currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
        },
        returnItemType(data) {
            if (util.isEmpty(data.ia_end_date)) {
                return '';
            }
            let currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            let diffTime = this.getDifferenceTime(data.ia_end_date, currentTime);
            if (diffTime === '00 : 00 : 00' && !this.getDataStatus) {
                this.getDataStatus = true;
                setTimeout(() => {
                    this.getDataStatus = false;
                    EventBus.$emit('getDetail');
                }, 1000 * 30)
            }
            return diffTime;
        },
        replaceBr(content) {
            if (util.isEmpty(content)) {
                return '';
            }
            return content.replace(/(\n|\r\n)/g, '<br>');
        },
        decodeHtml(html = '') {
            return util.htmlDecode(html);
        },

        returnCategoryName(data) {
            if (data.ic_idx === 2) {
                return this.$t('art')
            } else if (data.ic_idx === 3) {
                return this.$t('picture')
            } else if (data.ic_idx === 4) {
                return this.$t('video')
            } else if (data.ic_idx === 5) {
                return this.$t('music')
            } else if (data.ic_idx === 9) {
                return this.$t('collections')
            } else if(data.ic_idx === 178){
                return  this.$t('prompt');
            }
        },

        changeTerms() {
            this.termsCheck = !this.termsCheck;
        },
        getMemberData(data, type) {
            this.memberData = {}
            if (util.isEmpty(data[type])) {
                return
            }
            let params = {
                no: data[type]
            }
            this.$api.$member.getProfile(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type != 1 || util.isEmpty(res.List)) {
                    this.createAlert({
                        content: this.$t('detect_detail_empty'),
                        hide: () => {
                            this.$router.back();
                        }
                    })
                    return false;
                }
                this.memberData = res.List[0];
                this.$forceUpdate()
            })

        },
        returnMemberImg() {
            if (!util.isEmpty(this.memberData.Files) && !util.isEmpty(this.memberData.Files.Profile)) {
                return `${this.memberData.Files.Profile[0].org_url}?${this.setImageOptions(62, 62, 'png')}`
            }
        },
        checkMemberData() {
            return !util.isEmpty(this.memberData)
        },
        moveMember(url) {
            this.$router.push(`/${url}`)
        },
        checkMemberType() {
            if (!util.isEmpty(this.UserInfo) && this.itemData.provider === this.UserInfo.mb_no || this.UserInfo.g_idx === 2) {
                return false
            }
            return true
        },
        buyConfirmFunc() {
            this.createConfirm({
                content: this.$t('buy_bid_confirm'),
                confirmText: this.$t('yes'),
                cancelText: this.$t('cancel'),
                confirm: () => {
                    this.buyItem();
                },
                cancel: () => {
                    return false;
                },
            });
        },
        buyItem() {
            if (!this.termsCheck) {
                this.termsMsgType = true;
                return;
            } else {
                this.termsMsgType = false;
            }
            let bid_price = this.returnMaxPrice(this.bid_price);

            if (!this.isLogin()) {
                this.createConfirm({
                    content: this.$t('not_login_confirm'),
                    confirmText: this.$t('go_login'),
                    confirm: () => {
                        this.$router.push('/auth');
                    }
                })
                return false;
            } else {
                EventBus.$emit('checkValidator', 'auction', bid_price);
            }
        },
        setFeesData(price) {
            price = this.returnMaxPrice(price);
            this.fees = this.setFees(this.itemData, price);

            return util.Number.numFormat(this.fees);
        },
        setData(key, value) {
            this[key] = value;
        },
        returnTotalPrice() {
            let total = 0;
            let price = this.returnMaxPrice(this.bid_price)
            total = price + Number(this.fees);
            return total;
        },

        checkValidator(dataArr) {
            this.$validate(dataArr)
        },
        backEvent() {
            this.$router.back();
        },
        checkMaxNumber(type, value) {
            if (!util.isEmpty(value) && !isNaN(value)) {
                let price = util.Number.returnNumber(value);
                if (this.itemData.ia_is_immediately === 1 && price > this.itemData.ia_immediately) {
                    price = util.Number.returnNumber(this.itemData.ia_immediately)
                }
                this[type] = price;
            } else {
                this[type] = 0;
            }

        },
        slideChange() {
            this.slideIndex = this.$refs.swiper.$swiper.realIndex;
        },
        onClick(event) {
            if (util.isEmpty(event.target)) {
                return false;
            }
            let element = event.target;
            let elementSrc = element.currentSrc.split('?');

            if (util.isEmpty(elementSrc[0])) {
                return false;
            }
            this.changeThumnail(elementSrc[0]);
        },
    },
    watch: {
        "itemData": {
            deep: true,
            handler: function (val, oldVal) {
                this.initItemData()
            }
        }
    },
}
</script>

<style scoped>

</style>
